.bars {
  display: grid;
  place-items: center;
  cursor: pointer;
  z-index: 2;

  svg {
    stroke: black;
    transition: 0.2s;

    g {
      &:first-child {
        opacity: 1;
        transition: opacity 0s 0.2s;

        line {
          transition: transform 0.2s 0.2s;
          transform: translateY(0px);
        }
      }

      &:last-child {
        opacity: 0;
        transition: opacity 0s 0.2s;

        line {
          transition: transform 0.2s;
          transform: rotate(0deg);
          transform-origin: center;
        }
      }
    }
  }

  &.open svg {
    stroke: black;

    g {
      &:first-child {
        opacity: 0;

        line {
          transition: transform 0.2s;

          &:first-child {
            transform: translateY(7px);
          }

          &:last-child {
            transform: translateY(-7px);
          }
        }
      }

      &:last-child {
        opacity: 1;

        line {
          transition: transform 0.2s 0.2s;

          &:first-child {
            transform: rotate(45deg);
          }

          &:last-child {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}
