@import "/styles/variables.scss";
@import "/styles/font-bases.scss";

.header {
  width: 100%;
  position: fixed;
  transition: all 0.3s ease;
  background-color: $app-biege;
  z-index: 200;
  user-select: none;

  &.top {
    background-color: $app-biege;
  }
}

.wrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .middleLinks,
  .rightLinks {
    display: none;
  }

  &.menuOpen {
    height: var(--app-height, 100vh);

    .middleLinks,
    .rightLinks {
      display: flex;
    }
  }
}

.brandLogo {
  cursor: pointer;
  transition: filter 0.3s ease;

  &:hover {
    filter: invert(54%) sepia(23%) saturate(370%) hue-rotate(67deg) brightness(93%) contrast(92%);
  }
}

.backLink {
  @extend %app-typography;

  display: flex;
  align-items: center;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  transition: filter 0.3s ease;

  span {
    margin-left: 10px;
  }

  &:hover {
    filter: invert(54%) sepia(23%) saturate(370%) hue-rotate(67deg) brightness(93%) contrast(92%);
  }
}

.middleLinks {
  display: flex;
  flex-direction: column;
  transition: opacity 1s ease;

  a {
    @extend %text-xl-mobile;

    transition: color 0.3s ease;

    &.active,
    &:hover {
      color: $app-green-accent;
    }
  }

  & > :not(:first-of-type) {
    margin-top: 32px;
  }
}

.rightLinks {
  width: 100%;
  max-width: 335px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  transition: opacity 1s ease;

  .loginLink {
    @extend %app-typography, %app-typography-mobile;

    padding: 12px;
    display: flex;
    justify-content: center;
    color: white;
    background-color: $app-green;
    border-radius: 4px;
    transition: all 0.3s ease;

    span {
      margin-right: 10px;
    }

    &:hover {
      background-color: $app-green-accent;
    }
  }
}

.hamburger {
  position: absolute;
  top: 20px;
  right: 20px;
}

.localeSwitcherMobile {
  margin-top: 64px;
}

@media only screen and (min-width: 1024px) {
  .wrapper {
    padding: 20px;
    flex-direction: row;
    align-items: center;

    .middleLinks,
    .rightLinks {
      display: flex;
    }

    &.menuOpen {
      height: auto;
    }
  }

  .middleLinks {
    flex-direction: row;

    & > * {
      flex-shrink: 0;
    }

    & > :not(:first-of-type) {
      margin-top: 0;
      margin-left: 20px;
    }
  }

  .rightLinks {
    width: auto;
    margin: 0;

    .loginLink {
      color: black;
      background-color: transparent;

      &:hover {
        background-color: transparent;
        filter: invert(54%) sepia(23%) saturate(370%) hue-rotate(67deg) brightness(93%)
          contrast(92%);
      }
    }
  }
}

@media only screen and (min-width: 1280px) {
  .wrapper {
    max-width: 1440px;
    margin: 0 auto;
    padding: 26px 40px;
  }

  .middleLinks {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    & > :not(:first-of-type) {
      margin-left: 32px;
    }
  }
}
