@import "/styles/variables.scss";

.triggerIcon {
  display: inline-block;
  width: 28px;
  height: 28px;
  border: 1px solid $app-black;
  border-radius: 50%;
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 1px;
    background-color: $app-black;
    transition: all 0.3s ease;
  }

  &::before {
    left: 5px;
    top: 50%;
  }

  &::after {
    right: 5px;
    top: 13px;
    transform: rotate(90deg);
  }

  &.open {
    filter: invert(35%) sepia(28%) saturate(535%) hue-rotate(175deg) brightness(93%) contrast(84%);

    &::before {
      transform: rotate(180deg);
    }

    &::after {
      transform: rotate(180deg);
    }
  }
}
