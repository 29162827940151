@import "/styles/variables.scss";
@import "/styles/font-bases.scss";

.footer {
  background-color: $app-primary-dark;
  color: white;
}

.wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.mainSection {
  flex-shrink: 0;
  margin-bottom: 40px;
}

.imageLink {
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
}

.spacer {
  display: none;
}

.sectionWrapper {
  display: flex;
}

.section {
  h3 {
    @extend %text-sm, %text-sm-mobile;

    margin: 0 0 36px 0;
    text-transform: uppercase;
    color: $app-biege;
    letter-spacing: 0.05em;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li:not(:first-of-type) {
      margin-top: 12px;
    }
  }

  a:hover {
    text-decoration: underline;
  }

  &:last-of-type {
    max-width: 150px;

    ul li:not(:first-of-type) {
      margin-top: 20px;
    }
  }

  &:not(:first-of-type) {
    margin-top: 40px;
  }
}

.itemWithIcon {
  display: flex;
  align-items: flex-start;

  span:not(.label) {
    top: 3px;
    flex-shrink: 0;
  }

  .label {
    margin-left: 12px;

    p + p {
      margin-top: 12px;
    }
  }
}

.socialMediaLogos {
  margin-top: 42px;
  display: flex;

  a {
    margin-right: 16px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.copyright {
  opacity: 0.5;
  margin-top: 40px;
}

.localeButtons {
  margin-top: 24px;
}

@media only screen and (min-width: 640px) {
  .wrapper {
    padding: 64px 80px 64px 64px;
    flex-direction: row;
  }

  .mainSection {
    margin-right: 40px;
  }

  .copyright {
    margin-top: 50px;
  }

  .mainLinks {
    margin-left: 64px;
    display: grid;
  }
}

@media only screen and (min-width: 768px) {
  .wrapper {
    padding: 64px 40px;
  }

  .spacer {
    display: block;
    flex: 1 1 0;
  }

  .mainLinks {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 40px;
    row-gap: 40px;
    margin-left: 0;
  }

  .section:not(:first-of-type) {
    margin-top: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .wrapper {
    padding: 64px;
  }

  .mainLinks {
    column-gap: 80px;
  }
}

@media only screen and (min-width: 1280px) {
  .wrapper {
    .mainSection {
      margin-right: 70px;
    }
  }

  .mainLinks {
    display: flex;
    column-gap: 0;
    row-gap: 0;

    & > :not(:first-of-type) {
      margin-left: 80px;
    }
  }
}
