@import "/styles/font-bases.scss";

.wrapper {
  margin: 0 auto;
  padding: 100px 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    max-width: 734px;
    margin-top: 40px;
    text-align: center;
  }

  .description {
    @extend %text-1xl, %app-typography-p22;

    max-width: 664px;
    margin: 20px auto 0;
    text-align: center;
  }
}

.cover {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.coverImageRight {
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  // selector for the next.js <Image> component
  span {
    flex-shrink: 0;
  }

  h1 {
    max-width: 664px;
    text-align: left;
  }

  .description p {
    text-align: left;
    margin-right: 70px;
  }
}

.coverImageLeft {
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  // selector for the next.js <Image> component
  span {
    flex-shrink: 0;
  }

  h1 {
    max-width: 664px;
    text-align: right;
  }

  .description p {
    text-align: right;
  }
}

.links {
  margin-top: 32px;
}

@media only screen and (max-width: 1200px) {
  .wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .coverImageLeft,
  .coverImageRight {
    flex-direction: column;

    & > span {
      margin-bottom: 40px !important;
    }

    h1,
    .description p {
      text-align: center;
      margin: 0 auto;
    }
  }
}
