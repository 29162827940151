.container {
  padding-top: 41.6667%;
  max-width: 1440px - 40px * 2;
  margin: 0 auto;
  position: relative;

  &.wide {
    max-width: 100%;
  }
}

.heroImageWrapper {
  margin-top: 100px;
}

@media only screen and (min-width: 1536px) {
  .container {
    padding-top: 33.6667%;
  }
}

@media only screen and (max-width: 1360px) {
  .container {
    margin: 0 40px;

    &.wide {
      margin: 0 auto;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .container {
    margin: 0 20px;
  }
}

@media only screen and (max-width: 900px) {
  .container {
    padding-top: 56%;
  }

  .heroImageWrapper {
    margin-top: 76px;
  }
}
