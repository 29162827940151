.container {
  & > * {
    opacity: 0.5;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke,
      opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  & > .localeActive {
    opacity: 1;
  }

  & > *:not(:first-of-type) {
    margin-left: 24px;
  }
}
